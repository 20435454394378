var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg"},[_c('h2',[_vm._v("Technical Experience")]),_c('p',[_vm._v("The below word cloud is a representation of my skillset and experience. The words are weighted by significance and expertise.")]),_c('vue-word-cloud',{staticClass:"wordcloud",attrs:{"words":[
            ['Terraform', 50],
            ['AWS', 40],
            ['GCP', 20],
            ['Azure', 15],
            ['Linux', 30],
            ['Javascript', 35],
            ['Python', 30],
            ['Vue.js', 32],
            ['Bootstrap', 15],
            ['Serverless', 40],
            ['Bash', 30],
            ['Architecture', 35],
            ['DevOps', 40],
            ['UX/UI', 25],
            ['Docker', 30],
            ['Kubernetes', 30]
          ],"color":"White","font-family":"Avenir, Helvetica, Arial, sans-serif"}})],1),_vm._m(0)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-lg"},[_c('h2',[_vm._v("Certifications")]),_c('p',[_vm._v("The below certifications are active certifications achived by Miles Collier.")]),_c('div',{staticClass:"certs"},[_c('img',{attrs:{"src":require("@/assets/certs/hashicorp-certified-terraform-associate.png")}}),_c('img',{attrs:{"src":require("@/assets/certs/aws-certified-solutions-architect-associate.png")}})])])}]

export { render, staticRenderFns }