<template>
  <div class="navigation">
    <ul>
      <li>
        <router-link to="/" active-class="active">Home</router-link>
      </li>
      <li>
        <router-link to="/about">About</router-link>
      </li>
      <li>
        <router-link to="/work">Work</router-link>
      </li>
      <li>
        <router-link to="/contact">Contact</router-link>
      </li>
      <li class="float">
        <h4>Miles Collier</h4>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'Navigation'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.float {
  float: right;
}

.float h4 {
  margin-top: -10px;
  background-color: #ff4a4a;
  color: #000;
  padding: 5px 10px 5px 10px;
  border-radius: 10px;
}

h3 {
  margin: 40px 0 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;

}

li a:hover,
li a.router-link-active,
li a.router-link-exact-active {
  color: #ff4a4a;
  cursor: pointer;
}

ul li a {
  color: white;
  font-size: 23px;
  text-decoration: none;
}


@media only screen and (max-width: 850px) {

  ul {
    text-align:center;
    margin-bottom: 100px;
  }

  ul li {
    margin-top: 20px;
  }

  .float {
    float: none;
    margin-left: auto;
    margin-right: auto;
    margin-top: -90px;
    margin-bottom: 20px;
    display: block;
    text-align:center;
  }

  ul li a {
    font-size: 20px;
  }

  .navigation {
    margin-bottom: 0 !important;
  }
}

@media only screen and (max-width: 340px) and (min-width: 300px) {
  ul li a {
    font-size: 16px;
  }
}

@media only screen and (max-width: 300px) {
  ul li a {
    font-size: 12px;
  }
}



.navigation {
  margin-bottom:100px;

}
</style>
