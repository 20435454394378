<template>
  <div>
    <h1 style="font-size: 120px !important;">😱</h1>
    <h1>Not Found</h1>
    <p>The requested page doesn't exist, it never has and it never will. HOW DARE YOU?!?</p>
  </div>
</template>

<script>
export default {
  name: 'PageNotFound'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
li {
  display: inline-block;
  margin-right: 20px;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
</style>
