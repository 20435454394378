<template>
  <div>
    <h4>Mission</h4>
    <img src="~@/assets/miles_tesla.jpeg">
    <p>Contribute to the <mark>advancement of technology</mark>, as I believe it's the final solution to cure and fix all of humanities problems.
      I genuinely believe technology will allow us to <mark>completely resolve life's most critical issues</mark>.
      Such as bringing the sight back to the blind, hearing back to the hearing impaired, and resolve all mental issues and distress.
      One of the most productive paths in my eyes is the <mark>advancement of AI</mark>.
      If I contribute even a fraction to this advancement, I see myself as a success.</p>

    <h4>Professional</h4>
    <p>Miles actively works as an <mark>International Tech Consultant</mark>, emphesis on engineering and architecting enterprise systems in the Cloud.
      With 5 years of enterprise cloud engineering, Miles has been exposed to many facets of the technical industry.
      He has developed solutions, patterns, and techical designs for large enterprises that gross up to $20+ billion annually.
      These industries span finance, media, technical, and more around the globe! 🌍
    </p>

    <br>

    <p>Miles Collier is the <mark>founder of Icarus</mark>, a corporation that designs and build technical projects. More information can be found on <a href="https://icarus.io/">icarus.io</a>
    </p>

    <br>

    <p>Miles Collier is a Realtor and Investor,
      with years of being active in various Real Estate markets and handling large-scale misc.
      investments Miles has grasped many valuable skills on building and growing assets.
      Additionally he is active in the crypto-industry and has been an <mark>advocate for ₿ cryptocurrencies since 2013.</mark></p>


    <h4>Personal</h4>
    <p><mark>Computer nerd, travel lover, and seafood addict.</mark>
      When I'm not spending my time searching stackoverflow I'm finding something new and out of my comfort zone to try!<br><br>
      Some of my hobbies include (but are not limited to): Photography, travel, engineering, 3D Printing, politics, economics, and meeting new people!
      I'm <mark>currently residing in Colorado</mark> with family, but I travel often and try to experience everything life has to offer.
      I live life by the philosophy of "Innovate or Die", I think it's important to embrace new ideas to move all facets of life forward.

    </p>
  </div>


</template>

<script>
export default {
  name: 'About'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

img {
  border: 9px solid #fff;
  border-radius: 4px;
  transform: rotate(5deg);
  margin: 50px;
  width: 600px;
  float:right;
}

@media only screen and (max-width: 850px) {
  img {
    width: 90%;
    border: 3px solid #fff;
    text-align: center;
    display: block;
    height: auto;
    transform: rotate(3deg);
    float: none;
    margin: 50px auto 50px auto;
  }
}


body {
  background: #000 !important;
}
h3 {
  margin: 40px 0 0;
  font-weight: bold;
}

ul {
  list-style-type: none;
  padding: 0;
}

h4 {
  margin-bottom: 5px;
  margin-top: 50px;
  font-size: 35px;
}
li {
  display: inline-block;
  margin: 0 10px;
}

p {
  font-size: 23px;
  letter-spacing: -0.5px;
  color: #b7b7b7;
  margin: 0 0 0 0;
}

</style>
