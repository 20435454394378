<template>
  <div id="app">
    <Navigation></Navigation>
    <div class="content">
      <router-view></router-view>
    </div>

  </div>
</template>

<script>
import Navigation from './components/Navigation.vue'

export default {
  name: 'App',
  components: {
    Navigation
  }
}
</script>

<style>

body {
  font-size: 20px;
  padding-left: 85px;
  padding-right: 85px;
  background: #000 !important;
  margin-bottom: 130px !important;
}

.bg {
  background: url("~@/assets/miles_bg.jpg") no-repeat #000;
  background-size: 50%;
  background-position: 90% 100%;
}

@media only screen and (max-width: 850px) {
  body {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  p, ul li  {
    font-size: 20px !important;
  }
}


#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #fff;
  margin-top: 60px;
}

.content {
}


h1 {
  font-size: 63px !important;
}

h2 {
  font-size: 50px;
}

h3 {
  font-size: 40px;
}

h4 {
  font-size: 30px;
}

a, a:link, a:visited, a:active {
  color: #ff4a4a;
  font-weight: bold;
  text-decoration: none !important;
}

a:hover {
  color: #ff4a4a !important;
  text-decoration: none !important;
  cursor: pointer !important;
}

p {
  letter-spacing: -0.5px;
  color: #b7b7b7;
  margin: 0 0 0 0;
}

p, ul li {
  font-size: 23px;
}

mark {
  background-color: #ff4a4a !important;
  color: #000;
  text-decoration-color: #ff4a4a !important;
  padding-left: 5px;
  padding-right: 5px;
  text-decoration: underline;
}

blockquote {
  border-left: 10px solid #ccc;
  margin: 1.5em 10px;
  padding: 0.5em 10px;
  quotes: "\201C""\201D""\2018""\2019";
}

blockquote:before {
  color: #ccc;
  content: open-quote;
  font-size: 4em;
  line-height: 0.1em;
  margin-right: 0.25em;
  vertical-align: -0.4em;
}

blockquote p {
  display: inline;
  font-style: italic;
}

</style>
