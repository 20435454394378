<template>
  <div>

    <div class="container-fluid">
      <div class="row">
        <div class="col-lg order-lg-1 order-sm-2 order-2 order-md-2">
          <h4 class="subtitle">👋 Hi, I'm Miles!</h4>
          <h1 class="title">Engineer. Designer. Philanthropist. Investor.</h1>
          <p>Goal-oriented and highly driven technology professional and investor. </p>
          <ul>
            <li>Owner and Engineering Director of <a href="https://icarus.io/">Icarus, Inc.</a></li>
            <li>International Tech Constultant</li>
            <li>Licensed Realtor</li>
          </ul>
          <br>
          <div class="more">
            <router-link to="/about">Learn more about me ⇁</router-link>
          </div>
          <blockquote>
              <p>The reasonable man adapts himself to the world; <br>the unreasonable one persists in trying to adapt the world to himself.
              <br>Therefore, all progress depends on the unreasonable man. <br><span class="quote-author">~ George Bernard Shaw</span></p>
          </blockquote>
        </div>
        <div class="col-lg order-lg-2 order-sm-1 order-1 order-md-1">
          <img class="portrait" src="~@/assets/miles_bg.jpg">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Home'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.portrait {
  width: 100% !important;
  height: auto !important;
}


.column {
  float: left;
  width: 50%;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}
h3 {
  margin: 40px 0 0;
}

ul li {
  color: #b7b7b7;
}

.quote-author {
  padding-left: 30px;
  line-height: 10px;
  font-size: 20px;
}

.quote {
  font-size: 22px;
}
.title {
  margin-top: 20px;
  letter-spacing: -1.8px;
  line-height: 70px;
}

@media only screen and (max-width: 850px) {
  .title {
    font-size: 50px !important;
    line-height: 50px;
  }
  .subtitle {
    font-size: 25px;
  }
  .more a {
    font-size: 19px !important;
  }
}

.subtitle {
  margin-bottom: 0px;
  letter-spacing: -1.3px;
}


.more {
  margin-top: 10px;
  margin-bottom: 100px;
}

.more a {
  font-size: 23px;
}

.quote {
  margin-top: 100px;
}
</style>
