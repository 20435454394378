<template>
  <div>
    <h2>Let's Chat!</h2>
    <p>Best way to get in touch with me is via email, <a href="mailto:miles@milescollier.com">miles@milescollier.com</a>.</p>
    <br>
    <p>Find me on
    <ul>
      <li><a href="https://www.linkedin.com/in/milescollier/">LinkedIn</a></li>
      <li><a href="https://www.instagram.com/icarusmiles">Instagram</a></li>
      <li><a href="https://github.com/miles-collier/">Github</a></li>
      <li><a href="https://twitter.com/icarusmiles">Twitter</a></li>
    </ul>

  </p>
  </div>
</template>

<script>
export default {
  name: 'Contact'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
li {
  display: inline-block;
  margin-right: 20px;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
</style>
