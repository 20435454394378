import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import BootstrapVue from 'bootstrap-vue'
import VueWordCloud from 'vuewordcloud';

Vue.use(VueRouter)
Vue.use(BootstrapVue)
Vue.component(VueWordCloud.name, VueWordCloud);

Vue.config.productionTip = false

import Home from "@/components/Home.vue";
import About from "@/components/About.vue"
import Contact from "@/components/Contact.vue"
import Work from "@/components/Work.vue"
import PageNotFound from "@/components/PageNotFound.vue"

// Bootstrap
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'


let titlePrefix = "Miles Collier - ";

const routes = [
  { path: '/', component: Home, meta: { title: titlePrefix + "Home" } },
  { path: '/about', component: About, meta: { title: titlePrefix + "About" } },
  { path: '/contact', component: Contact, meta: { title: titlePrefix + "Contact" }},
  { path: '/work', component: Work, meta: { title: titlePrefix + 'Work'}},
  { path: "*", component: PageNotFound, meta: { title: titlePrefix + 'Not Found'}}
]


const router = new VueRouter({
  routes,
  mode: "history"
})

router.beforeEach((to, from, next) => {
  document.title = to.meta.title
  next()
})

new Vue({
  router,
  render: h => h(App),
  linkActiveClass: "active",
  linkExactActiveClass: "exact-active"
}).$mount('#app')
