<template>
  <div>




      <div class="row">
        <div class="col-lg">
          <h2>Technical Experience</h2>
          <p>The below word cloud is a representation of my skillset and experience. The words are weighted by significance and expertise.</p>
          <vue-word-cloud class="wordcloud"
            :words="[
              ['Terraform', 50],
              ['AWS', 40],
              ['GCP', 20],
              ['Azure', 15],
              ['Linux', 30],
              ['Javascript', 35],
              ['Python', 30],
              ['Vue.js', 32],
              ['Bootstrap', 15],
              ['Serverless', 40],
              ['Bash', 30],
              ['Architecture', 35],
              ['DevOps', 40],
              ['UX/UI', 25],
              ['Docker', 30],
              ['Kubernetes', 30]
            ]"
            color="White"
            font-family="Avenir, Helvetica, Arial, sans-serif"
          />
        </div>

        <div class="col-lg">
          <h2>Certifications</h2>
          <p>The below certifications are active certifications achived by Miles Collier.</p>
          <div class="certs">
            <img src="~@/assets/certs/hashicorp-certified-terraform-associate.png">
            <img src="~@/assets/certs/aws-certified-solutions-architect-associate.png">
          </div>
        </div>
      </div>

  </div>
</template>

<script>
export default {
  name: 'Contact'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
li {
  display: inline-block;
  margin-right: 20px;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.certs {
  padding: 10px;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  background-color: #fff;
  margin-top:40px;
}

.certs img {
  margin: 20px;
  width: 110px;
  height: 110px;
}

table {
  color: #b7b7b7;
}

.wordcloud {
  width: 700px !important;
  height: 500px !important;
}

@media only screen and (max-width: 850px) {
  .wordcloud {
    width: 100% !important;
    height: 400px !important;
  }

}
</style>
